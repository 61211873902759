.user-info-container {
    padding-bottom: 100px;
    padding-right: 1rem;
    padding-left: 1rem;
    direction: rtl;
}

.p-j-block{
    border: 1px solid rgba(42, 90, 161,0.2);
    border-radius: 12px;
    margin-bottom: 10px;
    text-align: right;
}