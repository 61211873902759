.topic-div {
        text-align: center;
        direction: rtl;
        margin-top: 1rem;
        border-radius: 0.3rem;
}

.file-div {
        text-align: center;
        direction: rtl;
}

.p-upload-button {
        background-color: rgb(50, 105, 176);
        color: white;
        padding: 0.5rem;
        width: 30%;
        font-family: sans-serif;
        border-radius: 0.3rem;
        cursor: pointer;
        margin-top: 1rem;
}

.d-submit-btn{
        text-align: center;
        direction: rtl;
}

.submit-btn{
        background-color: green;
        color: white;
        cursor: pointer;
        border-radius: 0.3rem;
        width: 20%;
}

.file-and-topic{
        z-index: 2;
        position: sticky;
}