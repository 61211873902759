.admin-page-background {
    background: #7cc7e7;
    background-image: radial-gradient(circle, #cbe5ee 0%, #7cc7e7 100%);
    width: 100vw;
    height: 100vh;
    direction: rtl;
    font-size: 3.7vw;
    font-weight: 600;
}

.mb-3-m {
    background-color: rgba(255, 255, 255, 1);
    padding-right: 0px;
}

.a-table {
    background-color: rgba(255, 255, 255, 1);
}

.we-a-ul {
    padding: 0px;
    list-style-position: outside;

}

.we-a-content {
    list-style-type: none;
    font-size: 14px;
    padding: 2px;

}

.wl-m-res {
    direction: rtl;
}

.we-a-li {
    list-style-type: none;
    padding: 2px;
}

.we-accordion-h {
    text-align: right;
}

/* Style buttons */
.xprt-btn {
    background-color: #1D6F42; /* Blue background */
    border: solid; /* Remove borders */
    border-color: #155230;
    border-radius: 12px;
    color: white; /* White text */
    padding: 2px 1px; /* Some padding */
    font-size: 10px; /* Set a font size */
    font-weight: bold;
    cursor: pointer; /* Mouse pointer on hover */
  }
  
  /* Darker background on mouse-over */
  .xprt-btn:hover {
    background-color: #155230;
  }

@media screen and (min-width: 601px) {
    .admin-page-background {
        font-size: 2vw;
    }
}

@media screen and (min-width: 1359px) {
    .admin-page-background {
        font-size: 1.1vw;
    }
}