.search-form{
 display:grid;
 grid-template-columns: repeat(auto-fit, minmax(60px,0.6fr));

 margin: auto;
}

/* Style buttons */
.srch-btn {
    background-color: DodgerBlue; /* Blue background */
    border: none; /* Remove borders */
    color: white; /* White text */
    font-size: 16px; /* Set a font size */
    cursor: pointer; /* Mouse pointer on hover */
    padding: 0px;
    width: 30px;
    height: 30px;
    margin-left: 1px;
    margin-right: 1px;
  }
  
  /* Darker background on mouse-over */
  .srch-btn:hover {
    background-color: RoyalBlue;
  }

  /* Style buttons */
.crss-btn {
  background-color: rgb(223, 43, 43); /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  font-size: 16px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
  padding: 0px;
  width: 30px;
  height: 30px;
}

/* Darker background on mouse-over */
.crss-btn:hover {
  background-color: rgb(147, 12, 12);
}

.upload-file-style{
  background-color: rgba(147, 12, 12, 0);
  direction: ltr;
}