.le-f-rev {
    text-align: center;
    direction: rtl;
    z-index: 9;
    position: sticky;
    background-color: rgba(255, 255, 255, 0);
}


.a-rev {
    background-color: rgb(255, 255, 255, 0);
}

.rev-box{
    text-align: right;
    padding-left: 0rem;
    padding-right: 0rem;
    margin: 0px;
}

ol,
ul {
    padding-right: 0.6rem;
    padding-left: 0rem;
    margin: 0px;
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem;
    margin-left: 0rem;
    margin: 0px;
}