.le-f-participation {
    text-align: center;
    direction: rtl;
    z-index: 9;
    position: sticky;
    background-color: rgba(255, 255, 255, 0);
}

.a-b-part {
    background-color: rgba(255, 255, 255, 0);
}

.part-a-href{
    font-size: 20px;
}

.a-part{
    background-color: rgba(255, 255, 255, 0);
}

.a-part>.accordion{
    background-color: rgba(255, 255, 255, 0);
}

.a-i-part{
    background-color: rgba(255, 255, 255, 0);
}

.a-part-1{
    background-color: rgba(255, 255, 255, 0);
}

.a-b-part-1{
    background-color: rgba(255, 255, 255, 0);
}

.accordion-collapse>.collapse>.show{
    background-color: rgba(255, 255, 255, 0);
}