
.rtl{
    direction: rtl;
}
.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    padding: 20px;
    width: 450px;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 6px;
    direction: rtl;
}

.comment-box{
    direction: rtl;
    padding:5px;
}



.comment-area textarea{
    direction: rtl;
   resize: none; 
        border: 1px solid #ad9f9f;
}


.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ffffff;
    outline: 0;
    box-shadow: 0 0 0 1px  rgb(255, 247, 0) !important;
}

.send {
    color: #fff;
    background-color:  rgb(23, 185, 26);
    border-color:  rgb(0, 159, 34);
}

.send:hover {
    color: #fff;
    background-color:   rgb(0, 159, 34);
    border-color:  rgb(0, 159, 34);
}


.rating {
    direction: rtl;
 display: flex;
        margin-top: -10px;
    flex-direction: row-reverse;
    margin-left: -4px;
        float: right;
}

.rating>input {
    display: none
}

.rating>label {
    direction: rtl;
        position: relative;
    width: 19px;
    font-size: 25px;
    color:  rgb(255, 247, 0);
    cursor: pointer;
}

.rating>label::before {
    content: "\2605";
    position: absolute;
    opacity: 0
}

.rating>label:hover:before,
.rating>label:hover~label:before {
    opacity: 1 !important
}

.rating>input:checked~label:before {
    opacity: 1
}

.rating:hover>input:checked~label:before {
    opacity: 0.4
}

