.app {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  position: sticky;
  z-index: 4;
  direction: rtl;
}

input[type="text"],
input[type="password"] {
  height: 25px;
  border-radius: 5px solid rgba(0, 0, 0, 0.2);
}

.sbmt-btn {
  background-color: #04AA6D;
  border: none;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 12px; 
  width: 100%;
}

.sbmt-btn:hover {
  background: #127b2b;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 18px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}