.t-dayname{
    direction:rtl;
    text-align: center;
}

.day-list-group{
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 1;

}

.day-body{
    direction: rtl;
    text-align: right;
    background-color: rgba(255, 255, 255, 0.5);
}