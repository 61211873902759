
.teachALesson{
    text-align: center;
    direction: rtl;
    z-index: 3;
    position: sticky;
    padding-left: 40px;
    padding-right: 30px;
}
.a-green{
    font-size: 20px;
    font-weight: bold;
    color: rgb(52, 50, 90);
}

.tl-lg{
    gap: 1rem;
}