.icon-container {
  z-index: 2;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(100px,0.1fr));/*this is what auto sizes the icons*/
  position: sticky;
  justify-content: center;
  padding-top: 20px;
}

.icon {
  font-size: 23px;
  direction:rtl;
}