@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.loading-spinner {
  width: 100px;
  height: 100px;
  border: 10px solid #9f9f9f;
  /* Light grey */
  border-top: 10px solid #376cd7;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
  position: absolute;
  transform: translate(-50%, -50%);
  margin: auto;
  padding: 10px;
}