.cards {
    z-index: 2;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(0px, 160px));
    gap: 1rem;
    grid-auto-rows: max-content;
    position: sticky;
    padding-bottom: 3rem;
    text-align: center;
    justify-content: center;
}

.pic-card-body{
    padding: 0px;
}

.a-green {
    color: green;
}

.a-red {
    color: red;
}